























































import { Component, Vue, Prop } from 'vue-property-decorator';

import FEEDBACKS from '../../constants';

const ProblemVideo = () => import('@/assets/icons/ProblemVideo.vue');

const INITIAL_VALUE_PROGRESS_WIDTH = '0';
const MESSAGE_ERROR = 'error';

@Component({
  components: {
    ProblemVideo
  }
})
export default class PlayerMessages extends Vue {
  @Prop({ required: true }) typeMessage!: string;
  @Prop({ default: INITIAL_VALUE_PROGRESS_WIDTH }) widthBackgroundProgress!: string;
  @Prop({ default: false }) lastVideoList!: string;
  @Prop({ default: false }) activeBtnProgress!: boolean;

  private MESSAGE_ERROR = MESSAGE_ERROR;

  get messageVideo() {
    if (!this.typeMessage) return null;

    return {
      type: FEEDBACKS[this.typeMessage]?.type,
      title: FEEDBACKS[this.typeMessage]?.title,
      btn: FEEDBACKS[this.typeMessage]?.btn,
      width: FEEDBACKS[this.typeMessage]?.width
    };
  }

  handleClickNextVideo() {
    this.$emit('clicked-next-video');
  }

  handleClickReplayVideo(messageType: string, textButton: string) {
    this.$emit('clicked-replay-video', messageType, textButton);
  }
}
